
export const LoginString = {
    ID: 'id',
    PhotoURL: 'PhotoURL',
    NAME: "name",
    EMAIL: "email",
    TOKEN: 'token',
    USERROLE: 'role',
    MIDNAME: "middleName",
    LASTNAME: "lastname"
}
